<!-- resources/js/MyComponent.vue -->
<template>
    <div class="bg-white block w-full md:flex h-full relative mx-auto rounded-md">
        <div class="relative h-full max-w-[100%]" style="width: 460px;">
            <div class="w-full h-full overflow-x-hidden">
                <div class="relative h-full w-full " style="min-width: 460px;">
                        <div class="py-4 px-4 md:px-4 text-gray-700 pb-[50px] grid grid-cols-2 gap-x-2 sm:block border-r-2 border-gray-200">
                            <div class="text-md font-bold pb-2 border-b border-[#DEE8F9]">📝 Start Writer</div>
                            <label class="flex text-sm font-semibold mt-3">Whats in your mind?</label>
                            <textarea rows="10" v-model="rawtext" class="w-full border-gray-200 rounded-md mt-1"></textarea>
                            <p class="text-xs text-right text-gray-700" :class="rawtext.length > 250 ? 'text-red-400':''">{{rawtext.length}}/250</p>

                            <button @click="doParaphrase()" :disabled="loading ||  token == null" class="flex flex-wrap mt-4 ml-0 bg-[#8730cb] hover:bg-[#610aa5] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline gap-x-2" type="button">
                                <svg v-if="loading" class="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Let's Write
                            </button>
                        </div>
                </div>
            </div>
        </div>
        <div class="flex-1 h-auto bg-white border-l p-4">

            <div class="flex-1">
                <ul class="space-y-14 md:space-y-6">
                    <li>
                        <h2 class="text-2xl font-bold md:text-xl">Choose use Case</h2>
                        <ul class="flex flex-wrap gap-4 mt-5 md:mt-3">
                            <li class="max-w-sm border border-[#E9EEF6] rounded-lg group transition-all hover:shadow-lg">
                                <a class="p-4 w-full h-full block" href="{{route('paraphrasingtool')}}">
                                    <div class="font-semibold group-hover:text-primary flex items-center space-x-2"><span class="inline-flex justify-center items-center i-cus--tools-hix-translate w-6 h-6"></span><span>Paraphrasing Tool</span></div>
                                    <p class="mt-1 text-sm text-display-secondary">HIX Translate is a ChatGPT-powered AI translator and the best alternative to Google Translate.</p>
                                </a>
                            </li>
                            <li class="max-w-sm border border-[#E9EEF6] rounded-lg group transition-all hover:shadow-lg">
                                <a class="p-4 w-full h-full block" href="#">
                                    <div class="font-semibold group-hover:text-primary flex items-center space-x-2"><span class="inline-flex justify-center items-center w-6 h-6" style="background-image:url(&quot;https://hix.ai/tool-icon/valueproposition.svg&quot;);background-size:contain;background-repeat:no-repeat;background-position:center"></span><span>Wedding Hashtag Generator</span></div>
                                    <p class="mt-1 text-sm text-display-secondary">Create the perfect wedding hashtag effortlessly with our AI-powered generator!</p>
                                </a>
                            </li>
                            <li class="max-w-sm border border-[#E9EEF6] rounded-lg group transition-all hover:shadow-lg">
                                <a class="p-4 w-full h-full block" href="#">
                                    <div class="font-semibold group-hover:text-primary flex items-center space-x-2"><span class="inline-flex justify-center items-center w-6 h-6" style="background-image:url(&quot;https://hix.ai/tool-icon/bachelorette-hashtaggenerator.svg&quot;);background-size:contain;background-repeat:no-repeat;background-position:center"></span><span>Bachelorette Hashtag Generator</span></div>
                                    <p class="mt-1 text-sm text-display-secondary">Make your bachelorette party unforgettable with our AI-powered hashtag generator!</p>
                                </a>
                            </li>
                            <li class="max-w-sm border border-[#E9EEF6] rounded-lg group transition-all hover:shadow-lg">
                                <a class="p-4 w-full h-full block" href="#">
                                    <div class="font-semibold group-hover:text-primary flex items-center space-x-2"><span class="inline-flex justify-center items-center w-6 h-6" style="background-image:url(&quot;https://hix.ai/tool-icon/valueproposition.svg&quot;);background-size:contain;background-repeat:no-repeat;background-position:center"></span><span>Wedding Hashtag Generator</span></div>
                                    <p class="mt-1 text-sm text-display-secondary">Create the perfect wedding hashtag effortlessly with our AI-powered generator!</p>
                                </a>
                            </li>
                            <li class="max-w-sm border border-[#E9EEF6] rounded-lg group transition-all hover:shadow-lg">
                                <a class="p-4 w-full h-full block" href="#">
                                    <div class="font-semibold group-hover:text-primary flex items-center space-x-2"><span class="inline-flex justify-center items-center w-6 h-6" style="background-image:url(&quot;https://hix.ai/tool-icon/bachelorette-hashtaggenerator.svg&quot;);background-size:contain;background-repeat:no-repeat;background-position:center"></span><span>Bachelorette Hashtag Generator</span></div>
                                    <p class="mt-1 text-sm text-display-secondary">Make your bachelorette party unforgettable with our AI-powered hashtag generator!</p>
                                </a>
                            </li>
                            <li class="max-w-sm border border-[#E9EEF6] rounded-lg group transition-all hover:shadow-lg">
                                <a class="p-4 w-full h-full block" href="#">
                                    <div class="font-semibold group-hover:text-primary flex items-center space-x-2"><span class="inline-flex justify-center items-center w-6 h-6" style="background-image:url(&quot;https://hix.ai/tool-icon/valueproposition.svg&quot;);background-size:contain;background-repeat:no-repeat;background-position:center"></span><span>Wedding Hashtag Generator</span></div>
                                    <p class="mt-1 text-sm text-display-secondary">Create the perfect wedding hashtag effortlessly with our AI-powered generator!</p>
                                </a>
                            </li>
                            <li class="max-w-sm border border-[#E9EEF6] rounded-lg group transition-all hover:shadow-lg">
                                <a class="p-4 w-full h-full block" href="#">
                                    <div class="font-semibold group-hover:text-primary flex items-center space-x-2"><span class="inline-flex justify-center items-center w-6 h-6" style="background-image:url(&quot;https://hix.ai/tool-icon/bachelorette-hashtaggenerator.svg&quot;);background-size:contain;background-repeat:no-repeat;background-position:center"></span><span>Bachelorette Hashtag Generator</span></div>
                                    <p class="mt-1 text-sm text-display-secondary">Make your bachelorette party unforgettable with our AI-powered hashtag generator!</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ref } from "vue";
import axios from "axios";
export default {
    components: {
        QuillEditor
    },
    props: {
        translations: Object,
    },
    data() {
        return {
            rawtext: '',
            editortext: null,
            loading: false
        };
    },
    mounted() {
        console.log('I am mounted')
    },

    methods: {
        doParaphrase() {
            console.log('doParaphrase')
            this.loading = true;
            axios
                .post("/tool/paraphrase", {
                    rawtext: this.rawtext,
                    lang: 'english',
                    mode: 'creative',
                })
                .then(response => {
                    this.loading = false;
                    console.log(response.data);
                    if (response.data.content.length) {
                        this.editortext = response.data.content;
                        console.log('I am success')
                    }
                })
                .catch(e => {
                    this.loading = false;
                    alert("something went wrong please try again.");
                });
        }
    }
};




</script>
<style>
.ql-editor {
    height: 100% !important;
}
.ql-container {
    height: auto !important;
}
.ql-container.ql-snow {
    border: none!important;
}
.ql-toolbar.ql-snow{
    border: none!important;
    border-bottom: 1px solid rgb(229 231 235);
}
</style>
