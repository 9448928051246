<!-- resources/js/MyComponent.vue -->
<template>
    <div class=" block w-full md:flex h-full relative mx-auto">
        <div class="relative h-full max-w-[1040px] mx-auto w-full">
            <div class="w-full flex h-full">
                <div class="relative h-full w-full grid grid-cols-2">
                    <div class="py-4 px-4 md:px-4 text-gray-700 pb-[50px] grid sm:block">
                        <h2 class="text-left text-base md:text-xl max-w-[760px] flex flex-wrap justify-items-center gap-2"><svg class="mt-2" focusable="false" aria-hidden="true" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none"><path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#5F6368"></path></svg> Create</h2>
                        <div class="flex flex-wrap gap-6 mt-4">
                            <div class="dropdown inline-block relative">
                                <button class="bg-violet-500 hover:bg-violet-700 text-white w-full font-semibold py-2 px-6 rounded-full inline-flex items-center">
                                    <span class="mr-1 flex flex-wrap"><svg class="mr-1 mt-1" focusable="false" aria-hidden="true" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg" width="12" height="16" fill="#ffffff" style="font-size: 16px;"><path d="M9.586 0C10.0556 0.000114731 10.5101 0.165434 10.87 0.467L11 0.586L15.414 5C15.746 5.33202 15.9506 5.77028 15.992 6.238L16 6.414V18C16.0002 18.5046 15.8096 18.9906 15.4665 19.3605C15.1234 19.7305 14.6532 19.9572 14.15 19.995L14 20H2C1.49542 20.0002 1.00943 19.8096 0.639452 19.4665C0.269471 19.1234 0.0428434 18.6532 0.00500021 18.15L1.00268e-07 18V2C-0.000159579 1.49542 0.190406 1.00943 0.533497 0.639452C0.876588 0.269471 1.34684 0.0428433 1.85 0.00500011L2 0H9.586ZM8 2H2V18H14V8H9.5C9.10218 8 8.72064 7.84196 8.43934 7.56066C8.15804 7.27936 8 6.89782 8 6.5V2ZM10 2.414V6H13.586L10 2.414Z"></path></svg> New Project</span>
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                                </button>
                                <ul class="dropdown-menu rounded shadow-lg z-10 [&_li]:!bg-white absolute hidden pt-1">
                                    <li class="rounded "><a class="hover:bg-gray-400 py-2 px-6 block whitespace-no-wrap" @click="createProject" href="#"><span class="mr-1 flex flex-wrap"><svg class="mr-1 mt-1" focusable="false" aria-hidden="true" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg" width="12" height="16" fill="#000" style="font-size: 16px;"><path d="M9.586 0C10.0556 0.000114731 10.5101 0.165434 10.87 0.467L11 0.586L15.414 5C15.746 5.33202 15.9506 5.77028 15.992 6.238L16 6.414V18C16.0002 18.5046 15.8096 18.9906 15.4665 19.3605C15.1234 19.7305 14.6532 19.9572 14.15 19.995L14 20H2C1.49542 20.0002 1.00943 19.8096 0.639452 19.4665C0.269471 19.1234 0.0428434 18.6532 0.00500021 18.15L1.00268e-07 18V2C-0.000159579 1.49542 0.190406 1.00943 0.533497 0.639452C0.876588 0.269471 1.34684 0.0428433 1.85 0.00500011L2 0H9.586ZM8 2H2V18H14V8H9.5C9.10218 8 8.72064 7.84196 8.43934 7.56066C8.15804 7.27936 8 6.89782 8 6.5V2ZM10 2.414V6H13.586L10 2.414Z"></path></svg> Blank Project</span></a></li>
                                </ul>
                            </div>
                            <button @click="doParaphrase()" :disabled="loading ||  token == null" class="flex flex-wrap ml-0 bg-white text-violet-500 hover:border-violet-800 border-1 font-semibold border-violet-700 py-2 px-4 rounded-full focus:outline-none focus:shadow-outline gap-x-2" type="button">
                                <svg class="" focusable="false" aria-hidden="true" viewBox="0 0 24 24" width="24" height="24" fill="#8b5cf6" xmlns="http://www.w3.org/2000/svg"><path d="M20.3333 3.66667V7H3.66667V3.66667H20.3333ZM20.3333 2H3.66667C3.22464 2 2.80072 2.17559 2.48816 2.48816C2.17559 2.80072 2 3.22464 2 3.66667V7C2 7.44203 2.17559 7.86595 2.48816 8.17851C2.80072 8.49107 3.22464 8.66667 3.66667 8.66667H20.3333C20.7754 8.66667 21.1993 8.49107 21.5118 8.17851C21.8244 7.86595 22 7.44203 22 7V3.66667C22 3.22464 21.8244 2.80072 21.5118 2.48816C21.1993 2.17559 20.7754 2 20.3333 2ZM7 12V20.3333H3.66667V12H7ZM7 10.3333H3.66667C3.22464 10.3333 2.80072 10.5089 2.48816 10.8215C2.17559 11.134 2 11.558 2 12V20.3333C2 20.7754 2.17559 21.1993 2.48816 21.5118C2.80072 21.8244 3.22464 22 3.66667 22H7C7.44203 22 7.86595 21.8244 8.17851 21.5118C8.49107 21.1993 8.66667 20.7754 8.66667 20.3333V12C8.66667 11.558 8.49107 11.134 8.17851 10.8215C7.86595 10.5089 7.44203 10.3333 7 10.3333ZM20.3333 12V20.3333H12V12H20.3333ZM20.3333 10.3333H12C11.558 10.3333 11.134 10.5089 10.8215 10.8215C10.5089 11.134 10.3333 11.558 10.3333 12V20.3333C10.3333 20.7754 10.5089 21.1993 10.8215 21.5118C11.134 21.8244 11.558 22 12 22H20.3333C20.7754 22 21.1993 21.8244 21.5118 21.5118C21.8244 21.1993 22 20.7754 22 20.3333V12C22 11.558 21.8244 11.134 21.5118 10.8215C21.1993 10.5089 20.7754 10.3333 20.3333 10.3333Z"></path></svg>
                                From Templates
                            </button>
                        </div>
                    </div>
                    <div class="py-4 px-4 md:px-4 text-gray-700 pb-[50px] grid grid-cols-2 sm:block">
                        <h2 class="text-left text-base md:text-xl max-w-[760px] flex flex-wrap justify-items-center gap-2"><svg class="mt-1" focusable="false" aria-hidden="true" viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 9L17.75 6.25L15 5L17.75 3.75L19 1L20.25 3.75L23 5L20.25 6.25L19 9ZM19 23L17.75 20.25L15 19L17.75 17.75L19 15L20.25 17.75L23 19L20.25 20.25L19 23ZM9 20L6.5 14.5L1 12L6.5 9.5L9 4L11.5 9.5L17 12L11.5 14.5L9 20ZM9 15.15L10 13L12.15 12L10 11L9 8.85L8 11L5.85 12L8 13L9 15.15Z" fill="#7C7C7C"></path></svg> Smart Start</h2>
                        <div class="shadow-xl inline-flex flex-wrap gap-1 bg-white p-1 mt-4 rounded w-full">
                            <div class="min-w-max dropdown inline-block relative">
                                <button class="bg-violet-500 text-white w-full font-semibold py-2 px-4 rounded inline-flex items-center">
                                    <span class="mr-1">{{selectedDocumentType}}</span>
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                                </button>
                                <ul class="dropdown-menu shadow-lg z-10 [&_li]:!bg-white absolute hidden pt-1 min-w-[180px]">
                                    <li v-for="(value, key, index)  in documentTypes" :class="[i === 1?'rounded-t': i === (documentTypes.length+1)?'rounded-t':'']" ><a @click="selectedDocumentType = value" class=" hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">{{value}} </a></li>
                                </ul>

                            </div>
                            <input v-model="rawtext" placeholder="Ex- Benefits of doing yoga in the morning" class="outline-none flex-1 mt-1 p-1">
                            <button @click="createProjectWithParam" class="flex items-center justify-center px-4 ">
                                <svg class="" focusable="false" aria-hidden="true" viewBox="0 0 22 22" width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 11C22 17.0752 17.0751 22 11 22C4.92486 22 0 17.0752 0 11C0 4.9248 4.92486 0 11 0C17.0751 0 22 4.9248 22 11ZM5 10C4.44772 10 4 10.4478 4 11C4 11.5522 4.44772 12 5 12H14.5858L11.2929 15.2928C10.9024 15.6834 10.9024 16.3166 11.2929 16.7072C11.6834 17.0976 12.3166 17.0976 12.7071 16.7072L17.7071 11.7072C18.0976 11.3166 18.0976 10.6834 17.7071 10.2928L12.7071 5.2928C12.3166 4.9024 11.6834 4.9024 11.2929 5.2928C10.9024 5.6834 10.9024 6.3166 11.2929 6.7072L14.5858 10H5Z" fill="#499557"></path></svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full flex h-full overflow-x-hidden">
                <div class="relative h-full w-full grid grid-cols-2">
                    <div class="py-4 px-4 md:px-4 text-gray-700 pb-[50px] grid sm:block">
                        <h2 class="text-left text-lg md:text-2xl max-w-[760px] flex flex-wrap justify-items-center gap-2"> My Projects</h2>
                        <div class="flex flex-wrap gap-6 mt-8">
                            <button @click="doParaphrase()" :disabled="loading ||  token == null" class="flex flex-wrap ml-0 bg-white gap-4 border-violet-500 border-2 text-violet-500 hover:border-violet-800 border-1 font-semibold border-violet-700 py-2 px-6 rounded-full focus:outline-none focus:shadow-outline gap-x-2" type="button">
                                <svg class="" focusable="false" aria-hidden="true" viewBox="0 0 24 24" width="24" height="24" fill="#8b5cf6" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.17 6L11.17 8H20V18H4V6H9.17ZM10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z" fill="#8b5cf6"></path><path d="M15 13.4037H12.4286V16.087H11.5714V13.4037H9V12.5093H11.5714V9.82608H12.4286V12.5093H15V13.4037Z" fill="#8b5cf6"></path></svg>
                                From Templates
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ref } from "vue";
import axios from "axios";
import ActionButtonsComponent from '@/Components/ActionButtonsComponent.vue';
export default {
    components: {
        QuillEditor,
        ActionButtonsComponent
    },
    props: {
        translations: Object,
    },
    data() {
        return {
            selectedDocumentType: 'Research Paper',
            documentTypes: [
                'Research Paper',
                'BLog',
                'Essay ',
                'Write Story',
            ],
            rawtext: '',
            editortext: null,
            loading: false,
            sitekey:'0x4AAAAAAAQWyAxhVrxfHtpm',
            token: null
        };
    },
    mounted(){
        this.renderCf();
    },
    beforeMount() {
        this.cfInit();
    },
    methods:{
        createProject(){
            window.location.href = '/ai-writer?project=temp';
        },
        createProjectWithParam(){
            window.location.href = '/ai-writer?project=temp&type='+this.selectedDocumentType+'&input='+this.rawtext;
        },
        cfInit(){
            const e = document.createElement("script"), t = "https://challenges.cloudflare.com/turnstile/v0/api.js";
            e.src = `${t}?onload=onloadTurnstileCallback&render=explicit`, e.async = !0, e.defer = !0, document.head.appendChild(e);
        },
        renderCf(){
            let _this = this;
            window.onloadTurnstileCallback = function () {
                turnstile.render('#cf-container', {
                    sitekey: _this.sitekey,
                    callback: function(token) {
                        _this.verify(token);
                    },
                });
            };
        },
        verify(token){
            this.token = token;
        },
        doParaphrase() {
            this.loading = true;
            axios
                .post("/tool/storyCharacter", {
                    capcha_token: this.token,
                    rawtext: this.rawtext,
                    lang: 'english',
                    mode: 'creative',
                })
                .then(response=>{
                    this.loading = false;
                    if (response.data.content.length){
                        this.editortext = response.data.content;
                    }
                    turnstile.reset('#cf-container')
                })
                .catch(e => {
                    let message = "something went wrong please try again.";
                    if(e.response.status == 422) {
                        message = e.response.data.message;
                    }
                    alert(message);
                    this.loading =false;
                    turnstile.reset('#cf-container')
                })
        }
    }
};

</script>
<style>
.ql-editor {
    height: 100% !important;
}
.ql-container {
    height: auto !important;
}
.ql-container.ql-snow {
    border: none!important;
}
.ql-toolbar.ql-snow{
    border: none!important;
    border-bottom: 1px solid rgb(229 231 235);
}
.dropdown:hover .dropdown-menu {
    display: block;
}
</style>
